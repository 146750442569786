import React from 'react';
import PropTypes from "prop-types";
import {Margin, StringUtils} from "../../Utilities";
import styles from "./button.module.scss"

export const Button = ({label, className, look = "fill", intent = "primary", fullWidth, disabled = false, type = "button", icon, onClick, onMouseOver, onFocus, onMouseLeave, innerRef, children, m, ml, mr, mx, mt, mb, my,}) => {
    let Class = StringUtils.joinIgnoreEmpty(" ", className, ((fullWidth) ? "w-100" : ""));
    Class += Margin({m, ml, mr, mx, mt, mb, my});
    const cls = `${styles[look]} ${styles[intent]} ${fullWidth ? styles.fullwidth : ""} ${Class}`;
    return (
        <button ref={innerRef} className={cls} type={type} disabled={disabled} onClick={onClick} onMouseOver={onMouseOver} onFocus={onFocus} onMouseLeave={onMouseLeave}>
            {icon}
            <span className={'d-inline-flex'}>{label}</span>
            {children}
        </button>
    )
};

Button.propTypes = {
    label: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    look: PropTypes.oneOf(["fill", "outline", "outline minimal", "outline minimal-fill", "link", 'minimal', 'custom']),
    intent: PropTypes.oneOf(["primary", "secondary", "danger", "success", "warning", "youtube"]),
    type: PropTypes.oneOf(["button", "submit", "reset"]),

    m: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    mt: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    mb: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    my: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    ml: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    mr: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
    mx: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", "0", "auto"]),
};

// export const Switch = ({className, id, onChange, size = "md", checked}) => {
//     const Class = StringUtils.joinIgnoreEmpty(" ", "switch-label", className, size);
//     return (
//         <>
//             <input className="switch-checkbox" id={id} type={"checkbox"} onChange={onChange} checked={checked}/>
//             <label className={Class} htmlFor={id}>
//                 <span className={`switch-button`}/>
//             </label>
//         </>
//     );
// };
// Switch.propTypes = {
//     className: PropTypes.string,
//     id: PropTypes.string,
//     size: PropTypes.oneOf(["sm", 'md', "lg", "xl"]),
// };
