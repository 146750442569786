import React, {useContext} from "react"
import {State} from "../components/State";
import SEO from "../components/seo"
import {YouTubeBackground, YouTubeBtn} from "../components/Framework/ui/YouTubeBackground/YouTubeBackground";
import Modal from "../components/Framework/ui/Modal/Modal";
import {Flex} from "../components/Framework/ui/Flex/Flex";
import YouTube from "react-youtube";
import {VideoItem} from "../components/Partials/VIdeoItem/VideoItem";
import {graphql} from "gatsby";
import ParallaxContainer from "../components/Framework/ui/ParallaxContainer/ParallaxContainer"
import PopOver from "../components/Framework/ui/PopOver/PopOver";
import {Icon} from "../components/Framework/ui/Icons/Icons";
import {customIcons} from "../components/Framework/ui/Icons/customIcons";
import {evaIconsFill} from "../components/Framework/ui/Icons/iconsEvaFill";
import scrollTo from 'gatsby-plugin-smoothscroll';
import commercialCover from "../images/commercial-cover.jpg";
import fashionCover from "../images/fashion-movies-banner.jpg";
import motivationalCover from "../images/motivational-trainloop.jpg";
import musicCover from "../images/music-banner.jpg";
import {Button} from "../components/Framework/ui/Button/Button";


const IndexPage = ({data}) => {
    const appState = useContext(State);
    const isMobile = appState.isMobile;

    const videos = data.allVideoJson.nodes;
    //Divide video data to categories
    const commercials = videos.slice(0, 4);
    const corporate = videos[4];
    const aerial = videos[5];
    const tvBroad = videos[6];
    const motivational = videos.slice(7, 9);
    const music = videos.slice(9, 13);
    const fashion = videos[13];


    return (
        <>
            <SEO title="Home"/>
            <YouTubeBackground videoID={"taWnVyv-kec"} height={550} heightLG={350} heightMD={250} heightSM={150}>
                <Modal trigger={<YouTubeBtn title={"Showreel"} description={"Showreel"}/>} modalType={"YouTube"} showClose={false}>
                    <YouTube opts={ytOptions} videoId={"taWnVyv-kec"}/>
                </Modal>
            </YouTubeBackground>
            <section className={"intro text-left text-lg-center"}>
                <h1>WE LOVE MAKING VIDEOS</h1>
                <p>Timecode Production is a collective of creative and inspired filmmakers. We believe that great productions are born from working together, sharing skills, knowledge, and a passion for beautiful imagery.</p>
            </section>

            <div id={"all"} className={isMobile ? "subMenuMobile" : "subMenu"}>
                {isMobile
                    ?
                    <PopOver target={<Flex alignItems={"center"}>Video Categories<Icon ml={"md"} size={"md"} icon={evaIconsFill.menu}/></Flex>}
                             targetClassName={"d-flex w-100 text-center justify-content-center"}
                             usePortal={true}
                             dismissOnClick={true}
                             positionFixed={true}
                             placement={"bottom"}
                             contentClassName={"text-center w-100"}
                             children={videoCategories.map(cat => <Button  className={"category-link"} look={"minimal"} fullWidth={true} onClick={() => scrollTo("#" + cat.id)}>{cat.label}</Button>)}
                    />
                    : videoCategories.map(cat => <Button look={"minimal"} onClick={() => scrollTo("#" + cat.id)}>{cat.label}</Button>)

                }

            </div>
            <ParallaxContainer className={"section-header"} image={commercialCover} offset={-1400} reverseDirection={true}>
                <q>The people who are crazy enough to think they can change the world are the ones who do</q>
                <Icon size={"md"} my={"md"} customColor={"rgba(255, 255, 255, .5)"} icon={customIcons.quotes}/>
                <span>Rob Siltanen</span>
            </ParallaxContainer>

            <section id={"commercials"} className={"dark-section pageWrapper"}>
                {commercials && commercials.map((item) => (
                    <VideoItem key={item.id} item={item}/>
                ))}
            </section>

            <div className={"section-header moving"} style={{backgroundImage: "url(" + motivationalCover + ")"}}>
                <q>Your VISION and your self-willingness is the MOST powerful elements to conquer your goal</q>
                <Icon size={"md"} my={"md"} customColor={"rgba(255, 255, 255, .5)"} icon={customIcons.quotes}/>
                <span>Rashedur Ryan Rahman</span>
            </div>

            <section className={"dark-section pageWrapper"}>
                <VideoItem id={"corporate"} key={corporate.id} item={corporate}/>
                <VideoItem id={"aerial-videos"} key={aerial.id} item={aerial}/>
                <VideoItem id={"tv-broadcasts"} key={tvBroad.id} item={tvBroad}/>
            </section>

            <ParallaxContainer className={"section-header"} image={fashionCover} offset={1000} speed={6} reverseDirection={false}>
                <q>Give yourself a great self-respect to know who you are then your confidence will shine on you</q>
                <Icon size={"md"} my={"md"} customColor={"rgba(255, 255, 255, .5)"} icon={customIcons.quotes}/>
                <span>Rashedur Ryan Rahman</span>
            </ParallaxContainer>

            <section id={"motivational"} className={"dark-section pageWrapper"}>
                {motivational && motivational.map((item) => (
                    <VideoItem key={item.id} item={item}/>
                ))}
            </section>

            <ParallaxContainer className={"section-header"} image={musicCover} offset={-1000} reverseDirection={true}>
                <q>Music gives a soul to the universe, wings to the mind, flight to the imagination and life to everything</q>
                <Icon size={"md"} my={"md"} customColor={"rgba(255, 255, 255, .5)"} icon={customIcons.quotes}/>
                <span>Plato</span>
            </ParallaxContainer>

            <section id={"music-videos"} className={"dark-section pageWrapper"}>
                {music && music.map((item) => (
                    <VideoItem key={item.id} item={item}/>
                ))}
                <VideoItem id={"fashion-films"} key={fashion.id} item={fashion}/>
            </section>

        </>
    )
};


export const query = graphql`
 {
    allVideoJson {
      nodes {
        title
        videoId
        coverUrl
        category
      }
    }
  }
`;
export default IndexPage

const ytOptions = {
    playerVars: {
        autoplay: 1,
        rel: 0
    }
};
export const videoCategories = [
    {
        key: 2,
        id: "commercials",
        label: "Commercials",
        value: "Commercials"
    },
    {
        key: 3,
        id: "corporate",
        label: "Corporate",
        value: "Corporate"
    },
    {
        key: 4,
        id: "aerial-videos",
        label: "Aerial Videos",
        value: "Aerial Videos",
    },
    {
        key: 5,
        id: "tv-broadcasts",
        label: "TV Broadcasts",
        value: "TV Broadcasts"
    },
    {
        key: 6,
        id: "motivational",
        label: "Motivational",
        value: "Motivational"
    },
    {
        key: 7,
        id: "music-videos",
        label: "Music Videos",
        value: "Music Videos"
    },
    {
        key: 8,
        id: "fashion-films",
        label: "Fashion Films",
        value: "Fashion Films"
    }

];
