import React from "react";
import ReactDOM from 'react-dom';
import {Button} from "../Button/Button";
import PropTypes from "prop-types";
import styles from "./modal.module.scss"
import {StringUtils} from "../../Utilities";


class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.modalWrapper = document.createElement('div');
        this.modalWrapper.classList.add(styles.wrapper);
        (this.props.className && this.modalWrapper.classList.add(this.props.className));
        this.modalWrapper.onclick = this.props.onClick;
    }

    componentDidMount() {
        document.body.appendChild(this.modalWrapper);
    }

    componentWillUnmount() {
        document.body.removeChild(this.modalWrapper);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.modalWrapper,
        );
    }
}

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showModal: false};
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleHideOnClickOutside = this.handleHideOnClickOutside.bind(this);
    }

    handleShow() {
        this.setState({showModal: true});
        document.body.style.overflow = 'hidden';
    }

    handleHide() {
        this.setState({showModal: false});
        document.body.style.overflow = null;
    }

    handleHideOnClickOutside(e) {
        let modal = document.getElementsByClassName('modal')[0];
        if (e.target !== modal) {
            this.setState({showModal: false});
            document.body.style.overflow = null;
        }
    }

    render() {
        let type;
        if (this.props.modalType === 'YouTube') {
            type = styles.ytVideo
        }

        const classWrapper = (this.props.wrapperClassName ? " " + this.props.wrapperClassName : "");
        const classModal = StringUtils.joinIgnoreEmpty(" ", 'modal', styles.modal, (this.props.modalClassName ? " " + this.props.modalClassName : ""), (this.props.modalType ? " " + type : ""));
        const classTrigger = StringUtils.joinIgnoreEmpty(" ", 'modal-trigger' + (this.props.triggerClassName ? " " + this.props.triggerClassName : ""));

        const modal = this.state.showModal ? (
            <Portal className={classWrapper} onClick={this.handleHideOnClickOutside}>
                <div className={classModal}>
                    {this.props.children}
                    {this.props.showClose && <Button className={'close'} onClick={this.handleHide} name={'Close'}/>}
                </div>
            </Portal>
        ) : null;

        return (
            <>
                <div tabIndex={0} role={"button"} onClick={this.handleShow} onKeyPress={this.handleShow} style={{display: "inline-flex", alignItems: "center"}} className={(classTrigger ? classTrigger : "")}>
                    {this.props.trigger}
                </div>
                {modal}
            </>
        );
    }
}

Modal.propTypes = {
    wrapperClassName: PropTypes.string,
    modalClassName : PropTypes.string,
    triggerClassName : PropTypes.string,
    trigger: PropTypes.any,
    modalType: PropTypes.oneOf(['YouTube'])
};
