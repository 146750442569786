import React from "react";
import ReactDOM from 'react-dom'
import {Manager, Reference, Popper} from 'react-popper';
import PropTypes from "prop-types";
import styles from "./popover.module.scss"

export default class PopOver extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.handleOpen = this.handleOpen.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleOpen() {
        if (!this.state.isOpen) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        if (this.node) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.handleOpen();
        }
    }

    handleIsOpen() {
        this.props.setIsOpen(this.state)
    }

    render() {
        const defaultMods = {
            offset: {enabled: true, offset: '0,10'},
            preventOverflow: {
                escapeWithReference: false
            }
        };


        let modifiers = this.props.modifiers;
        let mod = {...defaultMods, ...modifiers};
        const dismissOnClick = this.props.dismissOnClick && this.handleOpen;
        const content =
            <Popper innerRef={node => this.node = node}
                    placement={this.props.placement}
                    modifiers={mod}
                    positionFixed={this.props.positionFixed}
                    eventsEnabled={this.props.eventsEnabled}
                    children={({ref, style, placement, outOfBoundaries, arrowProps}) => (
                        <div ref={ref}
                             className={styles.content + (this.props.contentClassName ? " " + this.props.contentClassName : "")}
                             style={{opacity: outOfBoundaries ? 0 : 1, ...style}}
                             data-placement={placement}
                             data-arrow-placement={placement}
                             children={this.props.children.length > 0
                                 ? this.props.children.map((child, index) => <div role={"button"} tabIndex={0} key={index} className={this.props.itemClassName} onKeyPress={dismissOnClick} onClick={dismissOnClick}>{child}</div>)
                                 : <div role={"button"} tabIndex={0} className={this.props.itemClassName} onKeyPress={dismissOnClick} onClick={dismissOnClick}>{this.props.children}</div>
                             }
                        />
                    )}
            />;

        const portal = ReactDOM.createPortal(
            content,
            document.body
        );

        return (
            <>
                <Manager>
                    <Reference>
                        {({ref}) => (
                            <div role={"button"} tabIndex={0} ref={ref} onClick={this.handleOpen} onKeyPress={this.handleOpen} className={styles.target + (this.props.targetClassName ? " " + this.props.targetClassName : "")}>
                                {this.props.target}
                            </div>
                        )}
                    </Reference>
                    {this.state.isOpen ? this.props.usePortal ? portal : content : null}
                </Manager>
            </>
        );
    }
}

PopOver.propTypes = {
    targetClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    usePortal: PropTypes.bool,
    modifiers: PropTypes.object,
    positionFixed: PropTypes.bool,
    dismissOnClick: PropTypes.bool,
    setIsOpen: PropTypes.func,
    placement: PropTypes.oneOf(['auto', 'auto-end', 'top-start', 'top', 'top-end', 'right-start', 'right', 'right-end', 'bottom-end', 'bottom', 'bottom-start', 'left-end', 'left', 'left-start'])
};
