import React from "react";
import Modal from "../../Framework/ui/Modal/Modal";
import {YouTubeBtn} from "../../Framework/ui/YouTubeBackground/YouTubeBackground";
import YouTube from "react-youtube";
import styles from "./video-item.module.scss"

export const VideoItem = ({item, className, id}) => {
    const ytOptions = {
        playerVars: {
            autoplay: 1,
            rel: 0
        }
    };
    const backgroundImage = {
        backgroundImage: "url(" + item.coverUrl + ")"
    };
    return (
        <div className={styles.wrapper + (className ? " " + className : "")} style={backgroundImage} id={id}>
            <div className={styles.content} key={item.id}>
                <Modal trigger={<YouTubeBtn title={item.title} description={item.category}/>} modalType={"YouTube"} showClose={false}>
                    <YouTube opts={ytOptions} videoId={item.videoId}/>
                </Modal>
            </div>
        </div>
    )
};
