import React from "react";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import {CheckForScreens} from  '../../Utilities';
import {Button} from "../Button/Button";
import styles from './youtube-background.module.scss';



export const YouTubeBackground = ({videoID, height, heightSM, heightMD, heightLG, playerID, onReady, onPlay, onPause, onError, onStateChange, onPlaybackRateChange, onPlaybackQualityChange, videoImage, children}) => {
    const [isMobile, screen] = CheckForScreens();
    const opts = {
        playerVars: {
            autoplay: 1,
            controls: 0,
            disablekb: 1,
            modestbranding: 1,
            loop: 1,
            fs: 0,
            mute: 1,
        }
    };
    let containerHeight;
    if (screen === "phone") {
        containerHeight = heightSM + 'px';
    } else if (screen === "tablet") {
        containerHeight = heightMD + 'px';
    } else if (screen === "laptop") {
        containerHeight = heightLG + 'px';
    } else if (screen === "desktop") {
        containerHeight = height + 'px';
    } else {
        containerHeight = height + 'px';
    }

    const onEnd = (event) => {
        event.target.playVideo();
    };
    const containerStyle = {height: containerHeight};

    const imgBgStyles = {
        position: 'absolute',
        height: 100 + "%",
        width: 100 + "%",
        zIndex: -2,
        backgroundImage: "url('https://img.youtube.com/vi/" + videoID + "/maxresdefault.jpg')",
    };

    return (
        <div className={styles.container} style={containerStyle}>
            <div className={styles.bgImage} style={imgBgStyles}/>
            {!isMobile && screen !== "tablet" && screen !== "phone" &&
            <YouTube
                videoId={videoID}
                containerClassName={styles.bgVideo}
                className={styles.fgVideo}
                id={playerID}
                opts={opts}
                onReady={onReady}
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
                onError={onError}
                onStateChange={onStateChange}
                onPlaybackRateChange={onPlaybackRateChange}
                onPlaybackQualityChange={onPlaybackQualityChange}
            />
            }
            <div className={'video-description'}>
                {children}
            </div>
        </div>
    )
};

export const YouTubeBtn = ({title, description}) => {
    return (
        <div className={styles.btnContainer}>
            <Button className={styles.ytButton} intent={"youtube"}>
                <svg viewBox={"0 0 512 512"} width={18} fill={'#fff'}>
                    <path d="M42.8,49.9v412.2c0,25.2,27.2,41,49.1,28.5l360.7-206.1c22.1-12.6,22.1-44.4,0-57L91.9,21.4C70.1,8.9,42.8,24.7,42.8,49.9z"/>
                </svg>
            </Button>
            {title &&
            <div className={styles.ytBtnDesc}>
                <div className={"title"}>{title}</div>
                <div className={"desc"}>{description}</div>
            </div>
            }
        </div>
    )
};

YouTubeBackground.propTypes = {
    children: PropTypes.any,
    videoID: PropTypes.string,
    height: PropTypes.number,
    playerID: PropTypes.string,
    videoImage: PropTypes.any,
    onReady: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnd: PropTypes.func,
    onError: PropTypes.func,
    onStateChange: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onPlaybackQualityChange: PropTypes.func
};
